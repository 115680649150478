@import "../../assets/mainStyle";

$politicTitleBackground: #4cabc8;
$politicTextColor: #808487;
$modalButtonColor: #86878c;

.hidden-body {
  overflow: hidden;
}

.modal-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: scroll;
  background: rgba(0,0,0,.3);
}

.modal-wrapper {
  position: absolute;
  top: 50px;
  width: 100%;
  margin: 50px 0;
}

.politic {
  background: #fff;
  margin: 0 auto;
  position: relative;
  border-radius: 3px;
  width: 610px;
  padding: 60px 100px;

  &__title {
    @include text(20px, #fff);
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    background: $politicTitleBackground;
    margin: 35px 0 25px 0;
  }

  &__text {
    @include text(16px, $politicTextColor);
  }
}

.modal-button {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 15px;
  cursor: pointer;

  &__element {
    width: 20px;
    height: 2px;
    background: $modalButtonColor;
    border-radius: 5px;
    position: absolute;
  }

  &__left,&__right {
    top: 8px;
  }

  &__left {
    transform: rotate(-45deg);
  }

  &__right {
    transform: rotate(45deg);
  }
}