@import '../../assets/mainStyle';

$textColor: #4D9FCC;
$backgroundColor: #F2F6F7;

.footer {
  height: 56px;
  background:$backgroundColor;
  display: flex;
  align-items: center;
  padding: 0 20px;

  &__politic {
    @include text(13px, $textColor);
    cursor: pointer;
  }
}