@import '../../assets/mainStyle';

$titleColor: #4D9FCC;
$textColor: #B3B3B3;
$instructionTitleColor: #22B2BF;

.instructions {
  padding: 67px 0 0 0;
  &__title {
    @include text(23px, $titleColor);
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 67px 0;
  }
}

.instruction {
  display: flex;
  align-items: center;
  width: 391px;
  height: 198px;
  padding: 0 27px;
  margin: 0 41px 0 0;
  background: linear-gradient(140deg,#f6f8f9 20%, #FEFEFE 27.54%,#FEFEFE 4.56%);
  border-right: 1px solid #EEEFF0;
  border-bottom: 1px solid #EEEFF0;
  box-sizing: border-box;
  cursor: pointer;

  &__title,&__text {
    @include text(23px, $textColor);
    margin: 0;
  }

  &__title {
    text-transform: uppercase;
    color: $instructionTitleColor;
  }

  &-text-wrapper {
    margin: 0 0 0 33px;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
  }
}
