@import "../../assets/mainStyle";

$step: #B3B3B3;
$step_active: #22B2BF;
$titleColor: #4D9FCC;
$textColor: #BDBDBF;

.forExams {

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 61px 0;
  }

  &__title, &__text {
    @include text(23px, #333);
    text-align: center;
    margin: 0;
  }

  &__title {
    text-transform: uppercase;
    color: #4D9FCC;
    margin: 0 0 71px 0;
  }

  &__text {
    width: 640px;
    color: $textColor;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.arrow {
  width: 38px;
  height: 37px;
  mask-image: url("images/arrow.svg");
  background-color: $step;
  margin: 80px 33px 0 33px;

  &_active {
    background: $step_active;
  }
}

.forExams__steps-wrapper {
  display: flex;
  align-items: center;
  margin: 163px 0 0 0;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  outline: none;

  &__text {
    text-decoration: none;
  }

  &__block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;
    background: #FFFFFF;
    border: 1px solid $step;
    box-sizing: border-box;
    cursor: pointer;
    transition: 200ms;
    text-decoration: none;

    &_active {
      border: 1px solid $step_active;
    }

    &_final {
      border: 1px solid #BCC2C4;
      background: #BCC2C4;

      &_active {
        border: 1px solid $buttonColor;
        background: $buttonColor;

        &:hover {
          border: 1px solid $buttonHoverColor;
          background: $buttonHoverColor;
        }
      }
    }
  }

  &__title, &__text {
    font-family: $Arial;
    font-weight: 400;
    text-align: center;
    color: $step;
    margin: 0;

    &_active {
      color: $step_active;
    }
  }

  &__title {
    font-size: 32px;
    margin: 0 0 28px 0;
  }

  &__text {
    width: 240px;
    font-size: 23px;

    &_final {
      color: #fff;
    }
  }
}