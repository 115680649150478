@import "../../assets/mainStyle";

$title: #22B2BF;
$text: #4D9FCC;

@mixin text() {
  font-family: $Arial;
  font-weight: 400;
  font-size: 23px;
  text-align: center;
  margin: 0;
}

.request-success {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-wrapper {
    padding: 248px 0 309px 0;
  }

  &__title {
    @include text();
    text-transform: uppercase;
    color: $title;
    margin: 36px 0 55px 0;
  }

  &__text {
    width: 300px;
    @include text();
    color: $text;
  }
}