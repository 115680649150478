@import '../../assets/mainStyle';

$titleColor:#5686C1;
$textColor: #B3B3B3;

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 100px 0 0;

  &__image-wrapper {
    height: 555px;
  }
}

.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 584px;

  &__title {
    width: 537px;
    @include text(25px,$titleColor);
    text-align: center;
    margin: 0;
  }

  &__logo {
    margin: 33px 0 56px 0;
  }

  &__text {
    @include text(18px, $textColor);
    text-align: center;
    margin: 0;
  }
}