@import '../../assets/mainStyle';

$titleColor: #4D9FCC;
$textColor: #B3B3B3;
$requirementBackground: #F2F6F7;

.requirements {
  padding: 67px 0 148px 0;

  &__title {
    @include text(23px, $titleColor);
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 63px 0;
  }
}

.requirement {
  display: flex;
  align-items: center;
  width: 1043px;
  padding: 33px 54px 23px 54px;
  margin: 0 0 58px 0;
  background: $requirementBackground;
  box-sizing: border-box;

  &__text {
    width: 800px;
    @include text(23px,$textColor);
    margin: 0 0 0 50px;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}