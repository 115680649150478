@import "../../assets/mainStyle";

$titleColor: #4D9FCC;
$textColor: #BDBDBF;
$borderColor: #B3B3B3;
$distributiveTextColor: #B3B3B3;
$distributiveTitleColor: #22B2BF;

.form-distributive {
  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70vh;
    padding: 61px 0;
  }

  &__title {
    @include text(23px, $titleColor);
    text-transform: uppercase;
    text-align: center;
    margin: 0;
  }

  &__text {
    @include text(23px, $textColor);
    text-align: center;
    margin: 84px 0 0 0;
  }

  &__password {
   display: flex;
    flex-direction: column;
    position: relative;
    width: 490px;
    margin: 169px 0 0 0;

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__label {
    @include text(13px, $titleColor);
    margin: 0;
  }

  &__input {
    @include text(16px, $textColor);
    border: 1px solid $borderColor;
    outline: none;
    box-sizing: border-box;
    margin: 7px 0 0 0;
    letter-spacing: 7px;
    padding: 15px 20px;

    &::placeholder {
      letter-spacing: normal;
      color: #BDBDBF;
    }

    &:focus {
      //font-size: 30px;
      //padding: 5px 20px;

      &::placeholder {
        color: transparent;
      }
    }
  }

  &__button {
    @include text(19px, #fff);
    text-transform: uppercase;
    padding: 16px 71px;
    border: none;
    transition: 200ms;
    background: $buttonColor;
    outline: none;
    margin: 81px 0 0 0;
    &:hover {
      background: $buttonHoverColor;
    }
  }
}

.validation__not-valid {
  position: absolute;
  bottom: -19px;
}

.form__not-valid {
  border: 1px solid $notValidColor !important;
}

.form-distributive__instruction {
  display: flex;
  align-items: center;
  width: 391px;
  height: 198px;
  padding: 0 27px;
  margin: 187px 0 0 0;
  background: linear-gradient(140deg,#f6f8f9 20%, #FEFEFE 27.54%,#FEFEFE 4.56%);
  border-right: 1px solid #EEEFF0;
  border-bottom: 1px solid #EEEFF0;
  box-sizing: border-box;
  cursor: pointer;

  &__title,&__text {
    @include text(23px, $distributiveTitleColor);
    margin: 0;
  }

  &__title {
    text-transform: uppercase;
    color: $distributiveTextColor;
    margin: 0 0 30px 0;
  }

  &-text-wrapper {
    margin: 0 0 0 33px;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
  }
}