@import "../../assets/mainStyle";

$Arial: 'Arial';
$titleColor: #4D9FCC;
$inputColor: #757575;
$borderColor: #B3B3B3;
$textColor: #22B2BF;
$checkboxTextColor: #888D97;
$uploadFileColor: #C7C7C9;

.ask-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 61px 0;
}

.ask__title {
  @include text(23px, $titleColor);
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 56px 0;
}

.ask-form {
  display: flex;

  &-inside-wrapper {
    display: flex;
    flex-direction: column;
    width: 490px;
  }

  &__label {
    @include text(13px, $titleColor);

    &_not-valid {
      color: $notValidColor;
    }
  }

  &__input {
    @include text(13px, $inputColor);
    padding: 15px 20px;
    border: 1px solid #B3B3B3;
    box-sizing: border-box;
    margin: 7px 0 22px 0;
    outline: none;
  }

  &__textarea {
    height: 147px;
    @include text(13px, $inputColor);
    padding: 16px 20px;
    border: 1px solid $borderColor;
    box-sizing: border-box;
    resize: none;
    margin: 7px 0 36px 0;
    outline: none;
  }

  &__text {
    @include text(13px, $textColor);
    margin: 0 0 15px 0;

    &_not-valid {
      color: $notValidColor;
    }
  }

  &__checkbox {
    margin: 0 12px 0 0;

    &-text {
      @include text(13px, $checkboxTextColor);
    }
  }

  &__button {
    @include text(19px, #fff);
    text-transform: uppercase;
    background: $buttonColor;
    border: none;
    outline: none;
    padding: 20px 47px;
    margin: 74px auto;
    transition: 200ms;

    &:hover {
      background: $buttonHoverColor;
    }
  }

  &__drop-zone-wrapper {
    display: flex;
    flex-direction: column;
    width: 297px;
    margin: 0 0 0 86px;
  }

  &__drop-zone {
    margin: 7px 0 0 0;
    outline: none;
    cursor: pointer;
  }
}

.upload-file {
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  border-bottom: 1px solid $uploadFileColor;
  box-sizing: border-box;

  &-wrapper {
    width: 100%;
    height: 165px;
    overflow: auto;
    margin: 30px 0 0 0;
  }

  &__title {
    @include text(13px, $inputColor);
    margin: 0;
  }

  &__success {
    margin: 0 10px;
  }

  &__delete {
    cursor: pointer;
  }

  &__file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__text {
    display: flex;
    align-items: center;
  }
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.validation__not-valid {
  position: absolute;
  bottom: 0;
  right: 0;
  @include text(13px, $notValidColor);
}

.drop-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 140px;

  &_static {
    background: linear-gradient(rgb(87, 170, 192), rgb(98, 144, 194));

    & img {
      width: 9%;
    }

    & p {
      @include text(16px, #fff);
      width: 60%;
      margin: 20px 0 0 0;
      text-align: center;
    }
  }

  &_active {
    background: linear-gradient(rgb(68, 134, 152), rgb(77, 116, 154));

    & img {
      width: 15%;
    }
  }
}