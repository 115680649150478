@import "../../assets/mainStyle";

$backgroundColor: #F2F6F7;
$linkColor: #888D97;
$linkHoverColor: #22B2BF;
$statusBackgroundColor: #56B4CE;

.menu-wrapper {
  display: flex;
  position: sticky;
  height: 56px;
  top: 0;
  background: $backgroundColor;
  z-index: 2;
}

.menu {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.links {
  display: flex;
  &__link {
    @include text(16px, $linkColor);
    text-transform: uppercase;
    list-style: none;
    margin: 0 40px 0 0;
    cursor: pointer;
    transition: 200ms;
    &:hover {
      color: $linkHoverColor;
    }
  }
}

.logo-wrapper {
  margin: 0 244px 0 0;
}

.status-wrapper {
  width: 336px;
  height: 56px;
  margin: 0 0 0 30px;
  background: $statusBackgroundColor;
}

.status {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 10px;

  &__text {
    @include text(16px, #fff);
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 41px;
    height: 41px;
    border-radius: 50%;

    &_api {
      background: #BDBDBF;
    }

    &_user {
      background: #fff;
    }
  }

  &__api-status {
    position: absolute;
    right: -8px;
    bottom: -3px;
  }

  &__image {
    width: 100%;
  }
}