@import "../../assets/mainStyle";
$titleColor: #4D9FCC;
$textColor: #22B2BF;
$borderColor: #B3B3B3;
$inputColor: #757575;
$checkboxTextColor: #888D97;

.request {

  &-wrapper {
    padding: 61px 0 78px 0;
  }

  &__title {
    @include text(23px, $titleColor);
    text-align: center;
    margin: 0 0 50px 0;
  }
}

.request-form {
  display: flex;
  justify-content: center;

  &-wrapper {
    display: flex;
    flex-direction: column;
    width: 490px;
    margin: 0 87px 0 0;
  }

  &__label {
    @include text(13px, $titleColor);
    margin: 0 0 7px 0;

    &_checkbox {
      color: $checkboxTextColor;
    }
  }

  &__input {
    position: relative;
    height: 46px;
    @include text(13px, $inputColor);
    border: 1px solid $borderColor;
    padding: 16px 20px;
    box-sizing: border-box;
    margin: 0 0 23px 0;
    &:focus {
      outline: none;
    }
  }

  &__info {
    @include text(13px, $textColor);
    margin: 5px 0 14px 0;

    &_not-valid {
      color: $notValidColor;
    }
  }

  &__checkbox {
    margin: 0 13px 0 0;
    background: #FFFFFF;
    border: 1px solid $borderColor;
    box-sizing: border-box;
  }

  &__button {
    @include text(19px, #fff);
    text-transform: uppercase;
    background: $buttonColor;
    border: none;
    outline: none;
    padding: 20px 64px;
    margin: 37px 0 0 0;
    transition: 200ms;
    &:hover {
      background: $buttonHoverColor;
    }
  }
}

.calendars-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendars{
  position: relative;

  &:first-child {
    margin: 0 0 25px 0;
  }
}


.form__not-valid {
  border: 1px solid $notValidColor !important;
}

.validationRequest__not-valid {
  position: absolute;
  bottom: 0;
  right: 0;
  @include text(13px, $notValidColor);

  &_calendar {
    bottom: -14px !important;
  }
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}