@import "../../assets/mainStyle";
$requestColor: #61C6D2;
$requestColorHover: #22B2BF;
$requestsColor: #57B4CF;
$requestsColorHover: #3CA5C2;
$telegramColor: #6BA3D0;
$telegramColorHover: #488BC2;
$formColor: #7291C7;
$formColorHover:#577AB8;

.contacts-wrapper {
  display: flex;
  justify-content: center;
  height: 348px;
  background: #F2F6F7;
}

.contacts {
  display: flex;
  align-items: flex-end;
}

.contact {
  width: 330px;
  height: 296px;
  margin: 0 40px 0 0;
  &:last-child {
    margin: 0;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    background: linear-gradient(60.22deg, #FEFEFE 9.55%, #FEFEFE 31.05%, #F6F8F9 86.76%);
    border-top: 1px solid #fff;
    border-right: 1px solid #EEEFF0;
    box-sizing: border-box;
  }

  &__image {
    padding: 25px 0;
  }

  &__information-wrapper {
    transition: 200ms;
    cursor: pointer;
  }

  &__information-wrapper_request {
    background: $requestColor;
    &:hover {
      background: $requestColorHover;
    }
  }

  &__information-wrapper_requests {
    background: $requestsColor;
    &:hover {
      background: $requestsColorHover;
    }
  }

  &__information-wrapper_telegram {
    background: $telegramColor;
    &:hover {
      background: $telegramColorHover;
    }
  }

  &__information-wrapper_form {
    background: $formColor;
    &:hover {
      background: $formColorHover;
    }
  }

  &__information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 140px;
  }

  &__title,&__text {
    width: 240px;
    @include text(23px, #fff);
    margin: 0;
    text-align: center;
  }

  &__title {
    text-transform: uppercase;
  }
}

.link {
  text-decoration: none;
}