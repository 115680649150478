$Arial: 'Arial';
$notValidColor: #ED7A49;
$buttonColor: #46BCCA;
$buttonHoverColor: #22B2BF;

@mixin text($size,$color) {
  font-family: $Arial;
  font-size: $size;
  font-weight: 400;
  color: $color;
}

body {
  margin: 0;
  padding: 0;
  //position: relative;
}